// note: must attach to a form element

import { Controller } from "stimulus"
import $ from "jquery" // Ensure jQuery is imported

export default class extends Controller {
  connect() {
    // Expose this controller instance for external use
    this.element[this.identifier] = this;
    
    // Find the form element (the element this controller is attached to)
    this.formElement = this.element;
    
    // Find all textarea elements inside this form
    this.textareaElements = this.formElement.querySelectorAll('textarea');
    
    // Find the command key span inside the form (if it exists)
    this.commandKeySpanTarget = this.formElement.querySelector('[data-command-key-span]');
    
    // Determine if the platform is mobile or not (store the result for later use)
    this.isMobile = /Mobi|Android/i.test(navigator.userAgent);

    // Update the command key based on platform and mobile detection
    this.updateCommandKey();
    
    // Add the keydown event listener to the form (this will apply to all elements inside the form)
    this.formElement.addEventListener('keydown', this.handleKeydown.bind(this));

    if (!this.isMobile) {
      // Add focus and blur event listeners to textarea elements to toggle the visibility of the command key span
      this.textareaElements.forEach((textarea) => {
        textarea.addEventListener('focus', this.showCommandKey.bind(this));
        textarea.addEventListener('blur', this.hideCommandKey.bind(this));
      });
    }
  }

  disconnect() {
    // Clean up event listeners when the controller is disconnected
    if (this.formElement) {
      this.formElement.removeEventListener('keydown', this.handleKeydown.bind(this));
    }
    
    this.textareaElements.forEach((textarea) => {
      textarea.removeEventListener('focus', this.showCommandKey.bind(this));
      textarea.removeEventListener('blur', this.hideCommandKey.bind(this));
    });
  }

  updateCommandKey() {
    if (!this.commandKeySpanTarget) {
      return; // Exit gracefully if the target is missing
    }

    const isMac = navigator.platform.indexOf('Mac') !== -1;

    if (this.isMobile) {
      // Hide the entire span on mobile
      this.commandKeySpanTarget.style.display = 'none';
    } else {
      // Show the span and adjust text based on Mac or PC
      if (isMac) {
        this.commandKeySpanTarget.innerHTML = '⌘-enter submits';
      } else {
        this.commandKeySpanTarget.innerHTML = 'Ctrl-Enter submits';
      }
    }
  }

  handleKeydown(event) {
    // Submit the form on Command-Enter (Mac) or Ctrl-Enter (PC)
    if ((event.metaKey || event.ctrlKey) && event.key === "Enter") {
      console.log("Submitting form with Command/Ctrl + Enter");
      this.formElement.requestSubmit();
    }
  }

  showCommandKey() {
    // Fade in the command key span when the textarea is focused
    if (this.commandKeySpanTarget) {
      $(this.commandKeySpanTarget).fadeIn(300); // 300ms for fade-in
    }
  }

  hideCommandKey() {
    // Fade out the command key span when the textarea loses focus
    if (this.commandKeySpanTarget) {
      $(this.commandKeySpanTarget).fadeOut(300); // 300ms for fade-out
    }
  }
}
