import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    console.log("Onboarding controller connected");
    this.showScrim();
    this.showOnboardingCard();
    this.highlightCard();
  }

  disconnect() {
    this.close();
  }

  show() {
    this.showScrim();
    this.showOnboardingCard();
    this.highlightCard();
  }

  close() {
    this.hideScrim();
    this.hideOnboardingCard();
    this.removeHighlight();
  }

  showScrim() {
    const scrim = document.getElementById("scrim");
    if (scrim) {
      scrim.classList.remove("hidden");
      console.debug("Scrim shown");
    } else {
      console.warn("Scrim element not found");
    }
  }

  hideScrim() {
    const scrim = document.getElementById("scrim");
    if (scrim) {
      scrim.classList.add("hidden");
      console.debug("Scrim hidden");
    } else {
      console.warn("Scrim element not found");
    }
  }

  showOnboardingCard() {
    const onboardingContainer = document.getElementById("onboarding_container");
    if (onboardingContainer) {
      onboardingContainer.classList.remove("hidden");
      onboardingContainer.style.zIndex = 15; // Higher than the scrim, lower than the highlighted card
      onboardingContainer.style.position = "relative";
      console.debug("Onboarding card shown");
    } else {
      console.warn("Onboarding container not found");
    }
  }

  hideOnboardingCard() {
    const onboardingContainer = document.getElementById("onboarding_container");
    if (onboardingContainer) {
      onboardingContainer.classList.add("hidden");
      console.debug("Onboarding card hidden");
    } else {
      console.warn("Onboarding container not found");
    }
  }

  highlightCard() {
    this.highlightCard = this.element.dataset.onboardingHighlightCard;
    console.debug("Highlight card data attribute (using dataset):", this.highlightCard);

    if (this.highlightCard) {
      this.targetElement = document.querySelector(this.highlightCard);
      console.debug("Target element found:", this.targetElement);

      if (this.targetElement) {
        this.targetElement.classList.add("highlight-stripe-yellow");
        console.debug("Applied highlight-stripe-yellow to target element");

        // Ensure the target element is above the scrim
        this.targetElement.style.zIndex = 20;
        this.targetElement.style.position = "relative";
      } else {
        console.warn("No element found for selector:", this.highlightCard);
      }
    } else {
      console.warn("No highlight-card attribute provided");
    }
  }

  removeHighlight() {
    if (this.targetElement) {
      this.targetElement.classList.remove("highlight-stripe-yellow");
      this.targetElement.style.zIndex = "";
      this.targetElement.style.position = "";
      console.debug("Removed highlight-stripe-yellow from target element");
    } else {
      console.debug("No target element to remove highlight from");
    }
  }

  scrollTo() {
    const element = document.getElementById("onboarding_container");
    const elementTop = element.getBoundingClientRect().top + window.scrollY;
    const offset = 50; // Scroll 50px higher
    window.scrollTo({
      top: elementTop - offset,
      behavior: 'smooth',
    });
  }  

  assistantSuggest() {
    // raise #assistant-chat above the scrim
    const assistantChat = document.getElementById("assistant-chat");
    assistantChat.style.zIndex = 25;
    assistantChat.style.position = "relative";

    const assistantTextarea = $("#assistant-chat textarea")[0];
    assistantTextarea.value = this.element.dataset.onboardingAssistantSuggestion;
    assistantTextarea.focus();
    $(assistantTextarea).trigger('input');
  }
}
