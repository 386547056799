import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["item"];

  initialize() {
  }

  connect() {
    // see https://leastbad.com/stimulus-power-move
    this.element[this.identifier] = this;  
    // console.log("Connecting workspace controller...")
  }

  disconnect() {
    // console.log("Disconnecting workspace controller...")
  }

  load(event) {
    // event.preventDefault();

    // Remove "active" class from all list-group-item elements
    // const items = this.element.querySelectorAll('.list-group-item');
    const items = document.querySelectorAll('.list-group-item');

    items.forEach(item => {
      item.classList.remove('active');
    });

    // Retrieve data from the clicked item
    const workspaceId = event.currentTarget.dataset.workspaceId;
    const inbox = event.currentTarget.dataset.workspaceInbox !== undefined;
    const templates = event.currentTarget.dataset.workspaceTemplates !== undefined;

    $('.jobrow').remove();

    // for bootstrap-styled elements
    // $('#workspace .card').html('<div class="text-center mt-3 mb-3"><div class="mdl-spinner mdl-js-spinner is-active"></div></div>')
    // $('#workspace-right .card').html('<div class="text-center mt-3 mb-3"><div class="mdl-spinner mdl-js-spinner is-active"></div></div>')

    // for MDL-styled elements
    $('#workspace-right .mdl-card__supporting-text').html('<div class="text-center mt-3 mb-3"><div class="mdl-spinner mdl-js-spinner is-active"></div></div>')
    $('#workspace-right .mdl-card__supporting-text').html('<div class="text-center mt-3 mb-3"><div class="mdl-spinner mdl-js-spinner is-active"></div></div>')

    componentHandler.upgradeAllRegistered();

    $.ajax({
      url: '/collections/' + workspaceId + `?inbox=${inbox}&templates=${templates}`,
      success: function(result) {
        $('#workspace').html(result)
        hookupJavascript();
    }});

    $.ajax({
      url: '/collections/' + workspaceId + '/header',
      success: function(result) {
        $('#mainItemHeader').html(result)
        hookupJavascript();
      }
    })

    $.ajax({
      url: '/jobs/' + workspaceId + '/work_logs',
      success: function(result) {
        $('#notes-container').html(result)
        hookupJavascript();

        if ($(window).width() < 844) {
          $('#workspace')[0].scrollIntoView({behavior: 'smooth', alignto: 'top'});
        }
    }});

    $.ajax({
      url: '/jobs/' + workspaceId + '/attachments?skip_cta=true',
      success: function(result) {
        $('#attachments-container').html(result)
        hookupJavascript();
    }});

    $.ajax({
      url: '/collections/' + workspaceId + '/chat',
      success: function(result) {
        $('#assistant-chat').html(result)
        hookupJavascript();
      }
    });

    $.ajax({
      url: '/jobs/' + workspaceId + '/details',
      success: function(result) {
        $('#details-container').html(result)
        hookupJavascript();
      }
    })


  }

}
