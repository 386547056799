/* eslint no-console:0 */

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("stimulus-controllers")

import "@hotwired/turbo-rails"

import "bootstrap"
import "../stylesheets/application"

import "./drawer-right"
import "./extensions"
import "./job-search"
import "./job_actions"
import "./job_form"
import "./horizontal_scroll"
import "./modal"
import "./nav-tabs"
import "./work_logs_form"
import "./localize_time"
import "./address_autocomplete"
import "./judo"
import "./mdl-tooltip-hack"
import "./turbo-stream-customizations"

require("jquery-ui/ui/widget")
require("jquery-ui/ui/widgets/sortable")
require("jquery-ui/ui/widgets/droppable")

require('material-design-lite/material')

require('jquery-ui/ui/effects/effect-blind')
require("semantic-ui-api/api.min.js")
require("semantic-ui-search/search.min.js")

window.jQuery = $;
window.$ = $;

document.addEventListener('DOMContentLoaded', function () {
  const tableElement = document.querySelector('.mdl-data-table');
  if (tableElement) {
    new Tablesort(tableElement);
  }
});

document.addEventListener("turbo:load", () => {
  const fancyOptions = {};
  Fancybox.bind("[data-fancybox]", fancyOptions);
});
