import { StreamActions } from "@hotwired/turbo"

// Trying to turn off turbo on links everywhere due to broken streams after redirect
// It's off for links in the application.html body tag
// Turbo.setFormMode("off");

StreamActions.toast = function() {
  const text = this.templateContent.textContent
  $('#toast')[0].toast.showToast(text)
}

// Register a custom action called `set_value`
StreamActions.set_value = function() {
  const targetId = this.getAttribute("target");
  const content = this.templateContent.textContent.trim();
  const targetElement = document.getElementById(targetId);

  // Check if the targetElement exists and is not focused
  if (targetElement) {
    if (targetElement !== document.activeElement) {
      targetElement.value = content; // Set the new value of the target element (e.g., textarea)
    } else {
      $('#toast')[0].toast.showToast("The item you're editing has been updated by another user. Please refresh the page to see the changes.")
    }
  }
};

// Register a custom action called `set_data_attribute`
Turbo.StreamActions.set_data_attribute = function() {
  // Here, `this` refers to the Turbo Stream action context
  const targetId = this.getAttribute("target");
  const dataAttribute = this.getAttribute("data-attribute");
  const content = this.getAttribute("content");

  console.log("Target ID: " + targetId, "Data Attribute: " + dataAttribute, "Content: " + content);

  const targetElement = document.getElementById(targetId);

  // Check if the targetElement exists
  if (targetElement) {
    targetElement.setAttribute(dataAttribute, content); // Set the new data attribute value
  } else {
    console.log("Target element not found for set_data_attribute");
  }
};

document.addEventListener("turbo:before-stream-render", (event) => {
  const turboStreamElement = event.target;
  const action = turboStreamElement.action;
  const newContent = turboStreamElement.querySelector("template").content.firstElementChild;

  if (newContent) {
    const uniqueId = newContent.getAttribute("id");
    console.log("Unique ID: " + uniqueId);

    switch (action) {
      case "before":
      case "after":
      case "append":
      case "prepend":
        if (uniqueId) {
          const existingElement = document.getElementById(uniqueId);
          if (existingElement) {
            // Prevent duplicate append or prepend
            console.log("Preventing duplicate " + action);
            event.preventDefault();
          }
        }
        break;

      case "set_value":
        turboStreamSetValue(event, newContent);
        break;

      case "replace":
      case "update":
      case "remove":
      case "refresh":
        // Allow default behavior for these actions
        console.log(`Action '${action}' will proceed with default behavior.`);
        break;

      default:
        console.warn(`Unhandled action '${action}'; proceeding with default behavior.`);
    }
  }
});

function turboStreamSetValue(event, newContent) {
  const target = newContent.getAttribute("data-target");
  const content = newContent.textContent;
  if (target) {
    const targetElement = document.getElementById(target);
    if (targetElement) {
      targetElement.value = content; // Set the new value of the target element
      event.preventDefault(); // Prevent the default rendering behavior
      console.log(`Set value for target '${target}' to '${content}'.`);
    } else {
      console.warn(`Target element with ID '${target}' not found.`);
    }
  } else {
    console.warn("'data-target' attribute not found on new content.");
  }
}