hookupHorizontalScroll = function() {
  let isDragging = false;
  let startX, scrollLeft, scrollTarget;

  document.querySelectorAll(".horizontal-scroll").forEach((element) => {
    element.addEventListener("mousedown", (e) => {
      e.preventDefault();
      isDragging = true;
      scrollTarget = e.currentTarget;
      startX = e.pageX - scrollTarget.offsetLeft;
      scrollLeft = scrollTarget.scrollLeft;
      scrollTarget.classList.add("dragging");
    });

    element.addEventListener("mouseleave", () => {
      isDragging = false;
      scrollTarget?.classList.remove("dragging");
    });

    element.addEventListener("mouseup", () => {
      isDragging = false;
      scrollTarget?.classList.remove("dragging");
    });

    element.addEventListener("mousemove", (e) => {
      if (!isDragging) return;
      e.preventDefault();
      const x = e.pageX - scrollTarget.offsetLeft;
      const walk = (x - startX) * 2; // Adjust multiplier for speed
      scrollTarget.scrollLeft = scrollLeft - walk;
    });

    console.log("Horizontal scroll behavior hooked up for element", element);
  });
};

document.addEventListener("turbo:render", () => {
  // Initialize immediately after definition
  window.hookupHorizontalScroll();
});
