// app/javascript/controllers/delete_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    console.debug("DeleteController connected", { element: this.element });

    // Find delete buttons within the element this controller is attached to
    const deleteButtons = this.element.querySelectorAll(".btn-delete");

    if (deleteButtons.length === 0) {
      console.warn("DeleteController: No .btn-delete elements found", { element: this.element });
    }

    deleteButtons.forEach((button) => {
      button.addEventListener("ajax:send", this.handleSend);
      button.addEventListener("ajax:complete", this.handleComplete);
      console.debug("DeleteController: Listener added to .btn-delete", { button });
    });

    this.deleteButtons = deleteButtons; // Cache for cleanup
  }

  disconnect() {
    console.debug("DeleteController disconnected", { element: this.element });

    // Cleanup event listeners when the controller is disconnected
    if (this.deleteButtons) {
      this.deleteButtons.forEach((button) => {
        button.removeEventListener("ajax:send", this.handleSend);
        button.removeEventListener("ajax:complete", this.handleComplete);
        console.debug("DeleteController: Listener removed from .btn-delete", { button });
      });
    }
  }

  handleSend = (event) => {
    console.debug("DeleteController: ajax:send event triggered", { event });
    this.element.classList.add("animate");
    console.debug("DeleteController: Added animate class", { element: this.element });
  };

  handleComplete = (event) => {
    console.debug("DeleteController: ajax:complete event triggered", { event });
    this.element.remove();
    console.debug("DeleteController: Removed element", { element: this.element });
    showToast("Deleted");
  };
}
